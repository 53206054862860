import React from 'react'
import home from '../images/home.webp'
import pic7 from '../images/7pic.webp'
import pic71 from '../images/7pic1.webp'
import pic72 from '../images/7pic2.webp'
import pic73 from '../images/7pic3.webp'
import pic74 from '../images/7pic4.webp'
import pic75 from '../images/7pic5.webp'
import pic76 from '../images/7pic6.webp'
import Button from './Button'

const Screen1 = () => {
  return (
    <section>
      <div className=" flex justify-center items-center">
        <div className="relative">
          <img src={home} alt="Home" className="max-w-xs" />
          <div className="flex absolute top-[205px] left-[80px] overflow-hidden h-11 w-[148px]">
            <div className="relative flex h-full w-full">
              <ul className=" jackpotAni1 w-[44px]" id="ul1">
                <li>
                  <img src={pic75} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic76} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic74} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic73} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic71} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic72} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic7} alt="" height="40px" width="40px" />
                </li>
              </ul>
              <ul className="jackpotAni2 w-[44px]">
                <li>
                  <img src={pic74} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic73} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic76} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic75} alt="" height="40px" width="40px" />
                </li>

                <li>
                  <img src={pic72} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic71} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic7} alt="" height="40px" width="40px" />
                </li>
              </ul>
              <ul className="jackpotAni3 w-[44px]">
                <li>
                  <img src={pic76} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic75} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic74} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic73} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic72} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic71} alt="" height="40px" width="40px" />
                </li>
                <li>
                  <img src={pic7} alt="" height="40px" width="40px" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container flex flex-col items-center justify-center gap-7 pb-7">
        <h1 className=" text-3xl text-center font-['Staat']">
          KARLO DUNIYA MUTTHI ME, KHELKE ZETBET KE SAATH
        </h1>
        <h2 className="text-2xl text-center font-medium">
          India’s Most Trusted book since 2017
        </h2>
        <p className="text-xl text-center font-medium">
          For new ID Whatsapp On
        </p>
        <Button number={"+977 980555555"}/>
        <Button number={"+977 980666666"} />
      </div>
    </section>
  )
}

export default Screen1
