import React from "react";
import Button from "./Button";

const Screen4 = () => {
  return (
    <section>
      <div className="flex flex-col gap-5 container py-10">
        <h3 className="textGradient font-extrabold text-3xl">
          The Punter Friendly Book
        </h3>
        <p className="font-semibold text-xl">
          We deal with all branded sites and present the fastest withdrawal
          service
        </p>
        <h4 className="text-white text-xl">We Deal Only On whatsapp</h4>
        <div className="flex flex-col justify-center items-center gap-5">
          <Button number={"+977 980999999"} />
          <Button number={"+977 980000000"}/>
        </div>
      </div>
    </section>
  );
};

export default Screen4;
