import React from "react";
import upi from "../images/upi.png";
import mastercard from "../images/mastercard.png";
import visa from "../images/visa.png";
import imps from "../images/imps.png";
import lady from "../images/lady.webp";
import telegram from "../images/telegram.png";
import facebook from "../images/facebook.png";
import insta from "../images/insta.png";
import eighteen from "../images/eighteen.png";
import betfair from "../images/betfair.png";
// import logo from "../images/logo.png";
import Button from "./Button";
import { scrollAnimation } from "./constant/animation";

const Screen6 = () => {
  window.addEventListener("scroll", scrollAnimation);

  return (
    <section>
      <div className="flex flex-col gap-5 container py-10 overflow-hidden">
        <h3 className="textGradient font-extrabold text-3xl ">
          Get in touch with customer care
        </h3>
        <p className="font-semibold text-xl">
          For Any Queries, Emergencies, Feedbacks or Complaints. We are here to
          help you 24/7 with our online services
        </p>
        <p className="font-semibold text-xl">We accept transactions through</p>
        <div className="flex flex-wrap items-center justify-center gap-2 revealLeft">
          <img src={upi} alt="upi" />
          <img src={mastercard} alt="mastercard" />
          <img src={visa} alt="visa" />
          <img src={imps} alt="imps" />
        </div>
        <div className="  flex justify-center items-center">
          {/* <img src={logo} className="absolute top-48" alt="" /> */}
          <div className="relative w-[280px] revealLeft">
            <img src={lady} alt="lady" />
            {/* <div className="absolute top-[160px] left-[100px]">
              <img src={logo} alt="logo" />
            </div> */}
          </div>
        </div>
        <div className="flex justify-center flex-col gap-5 items-center">
          <h4 className="text-white text-xl">Whatsapp us on</h4>
          <Button number={"+977 980111111"} />
          <Button number={"+977 980222222"}/>
          <h4 className="text-white text-xl">Customer Care Number</h4>
          <Button number={"+977 980333333"} />
          <Button number={"+977 980444444"}/>
          <h4 className="text-white text-xl">Follow Us</h4>
          <div className="flex gap-5">
            <a href="https://api.whatsapp.com/send?phone=+917600435933&amp;text=Welcome to ZetBet" target="_blank" rel="noopener noreferrer">
              <img src={telegram} alt="telegram" />
            </a>
            <a href="https://api.whatsapp.com/send?phone=+917600435933&amp;text=Welcome to ZetBet" target="_blank" rel="noopener noreferrer">
              <img src={facebook} alt="facebook" /></a>
            <a href="https://api.whatsapp.com/send?phone=+917600435933&amp;text=Welcome to ZetBet" target="_blank" rel="noopener noreferrer">
              <img src={insta} alt="insta" /></a>
          </div>
          <p className="font-medium text-sm flex gap-2">
            <img src={eighteen} alt="eighteen" />
            Under age gambling is an offence
          </p>
        </div>
        <div className="flex justify-center items-center gap-5">
          <h4 className="text-xl flex gap-5">Powered By</h4>
          <img src={betfair} alt="betfair" />
        </div>
      </div>
    </section>
  );
};

export default Screen6;
